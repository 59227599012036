<template>
  <div class="phone">
    <div class="title">请输入要绑定的手机号</div>
    <el-form ref="phoneForm" :rules="rules" :model="form" label-width="0px">
      <el-form-item label="" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="sjToken">
        <div class="codeBox">
          <el-input
            v-model="form.sjToken"
            placeholder="请输入手机验证码"
          ></el-input>
          <button
            type="button"
            class="boxbssHdBtn"
            :class="time > 0 ? 'notClick' : ''"
            @click="getPhoneCode"
            :disable="disable"
          >
            {{ titles }}
          </button>
        </div>
      </el-form-item>
    </el-form>
    <span class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="open">确 定</el-button>
    </span>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue"; 
import { loginSmsCode } from "@/api/login";
import { pcUserBindAcc } from "@/api/user";
export default {
  components: {},

  data() {
    return {
      disable: false,
      time: 0,
      userId: null,
      titles: "获取验证码",
      form: {
        mobile: "",
        sjToken: "",
      },

      rules: {
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        sjToken: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.userId = localStorage.getItem("userId");
  },
  methods: {
    open() {
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
          pcUserBindAcc(this.form.mobile, this.form.sjToken).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "绑定成功，请重新登录",
              });
              this.$emit("isOk");
              setTimeout(() => {
                Vue.prototype.goLoginView(false);
              }, 2000);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    cancel() {
      this.$emit("isOk");
    },
    // 注册验证码
    getPhoneCode() {
      if (this.time > 0) {
        return;
      }
      if (!/^[1]([3-9])[0-9]{9}$/.test(this.form.mobile)) {
        this.$message.error("手机号格式不正确");
        return;
      }
      loginSmsCode(this.form.mobile, "confirmToken").then((res) => {
        if (res.code == 0) {
          this.time = 60;
          this.$message.success(res.msg);
          var wws = setInterval(() => {
            if (this.time < 1) {
              this.titles = "获取验证码";
              this.disable = false;
              clearInterval(wws);
            } else {
              this.titles = this.time-- + "秒后重发";
              this.disable = true;
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.phone {
  .title {
    margin-bottom: 16px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
  .codeBox {
    display: flex;
    align-items: center;
    .boxbssHdBtn {
      width: 87px;
      height: 40px;
      outline: none;
      background: #eeeeee;
      border: none;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff4027;
      cursor: pointer;
      flex-shrink: 0;
      margin-left: 20px;
    }
  }
}
</style>
